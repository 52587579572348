<script>
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import CustomCardTitle from "@/components/custom-card/custom-card-title.vue";
import Swal from "sweetalert2";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import {dateUtil} from "@/helpers/date-util";
import {timeUtil} from "@/helpers/time-util";
import CreateStakingModal from "@/components/grzegorz/create-staking-modal.vue";
import parsePhoneNumber from "libphonenumber-js";

export default {

  components: {
    Layout,
    PageHeader,
    CustomCardTitle,
    CreateStakingModal
  },

  data() {
    return {
      intervalId: 0,

      walletDetails: null,
      currentBalance: null,
      proofOfStakeDetails: null,
      proofOfStakeTotalReceived: null,

      allowedToView: false,

      form: {
        secretCode: ""
      },

      notificationRule: null,
      notificationRuleOptions: {
        phoneNumbers: [],
        sendBelowAccAmount: false,
        sendBelowBnbAmount: false
      },

      stakingList: []
    }
  },

  computed: {
    timeUtil() {
      return timeUtil
    },

    dateUtil() {
      return dateUtil
    }
  },

  filters: {
    formatEthBalance(value) {
      if (!value) {
        return '0.00';
      }

      return new Intl.NumberFormat('en-US', {minimumFractionDigits: 2, maximumFractionDigits: 18}).format(value);
    }
  },

  methods: {
    getItems() {
      return [
        {
          text: "ecat"
        },
        {
          text: "Aderlo Cashback",
          active: true
        }
      ]
    },

    getBalance(tokenSymbol) {
      if (!this.walletDetails) {
        return 0
      }

      const balanceData = this.walletDetails.balanceData.balances.find(element => element.symbol === tokenSymbol)
      return balanceData ? balanceData.balance : 0
    },

    async copyToClipboard(text) {
      try {
        await navigator.clipboard.writeText(text);
        await Swal.fire("", "Skopiowano Adres Portfela!", "success");
      } catch (error) {
        console.error(error);
      }
    },

    async loadWalletDetails(checkSecurityCode) {
      if (!this.form.secretCode) {
        return
      }

      if (!this.allowedToView && !checkSecurityCode) {
        return
      }

      try {
        const {data} = await axios.get(`/user/cryptocurrency/wallet/internal/details`, {
          data: {},
          headers: {
            'Secret-Code': this.form.secretCode,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        if (checkSecurityCode) {
          await this.loadNotificationRule()
          await this.loadStakingList()
          this.allowedToView = true
        }

        this.walletDetails = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadStakingList() {
      try {
        const {data} = await axios.get(`/cryptocurrency/staking/reward`, {
          data: {},
          headers: {
            'Secret-Code': this.form.secretCode,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.stakingList = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadNotificationRule() {
      try {
        const {data} = await axios.get(`/cryptocurrency/notification/rule`, {
          data: {},
          headers: {
            'Secret-Code': this.form.secretCode,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.notificationRule = data[0]

        this.notificationRuleOptions.phoneNumbers = JSON.parse(this.notificationRule.phoneNumbers)
        this.notificationRuleOptions.sendBelowAccAmount = !!this.notificationRule.sendBelowAccAmount
        this.notificationRuleOptions.sendBelowBnbAmount = !!this.notificationRule.sendBelowBnbAmount
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async loadProofOfStakeTotalReceived() {
      try {
        const {data} = await axios.get(`/user/cryptocurrency/proof-of-stake/total-received/ACC`, {
          data: {},
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        });

        this.proofOfStakeTotalReceived = data
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    async updateNotificationRule() {
      for (const phoneNumber of this.notificationRuleOptions.phoneNumbers) {
        if (!phoneNumber) {
          Swal.fire(this.$t("message.error"), "Numer telefonu nie może być pusty!", "error")
          return
        }

        if (!this.isPossibleNumber(phoneNumber)) {
          Swal.fire(this.$t("message.error"), "Wprowadzono błędny numer telefonu!", "error")
          return
        }
      }

      try {
        const json = {
          ...this.notificationRule,
          phoneNumbers: JSON.stringify(this.notificationRuleOptions.phoneNumbers),
          sendBelowAccAmount: this.notificationRuleOptions.sendBelowAccAmount ? this.notificationRule.sendBelowAccAmount || 0 : null,
          sendBelowBnbAmount: this.notificationRuleOptions.sendBelowBnbAmount ? this.notificationRule.sendBelowBnbAmount || 0 : null
        }

        await axios.put(`/cryptocurrency/notification/rule`, json, {
          headers: {
            'Secret-Code': this.secretCode,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        Swal.fire(this.$t("message.success"), "Pomyslnie zapisano zmiany", "success")
            .then(async () => await this.loadNotificationRule())
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    isPossibleNumber(number) {
      const phoneNumber = parsePhoneNumber(number)
      if (!phoneNumber) {
        return false
      }

      return phoneNumber.isValid()
    },

    changePhoneNumber(index, value) {
      this.notificationRuleOptions.phoneNumbers[index] = value
    },

    addPhoneNumber() {
      this.notificationRuleOptions.phoneNumbers.push("")
    },

    removePhoneNumber(index) {
      this.notificationRuleOptions.phoneNumbers.splice(index, 1);
    },

    async initAll() {
      const that = this
      this.intervalId = setInterval(async () => {
        await that.loadWalletDetails(false)
        await that.loadProofOfStakeTotalReceived()
      }, 5000);
    }
  },

  async created() {
    await this.initAll()
  },

  beforeDestroy() {
    if (!this.intervalId) {
      return
    }

    clearInterval(this.intervalId);
  }
};
</script>

<template>
  <Layout>
    <PageHeader title="Grzegorz" :items="getItems()"/>
    <create-staking-modal
        :secret-code="form.secretCode"
        @load-staking-list="loadStakingList"
        ref="createStakingModal"
    />

    <div v-if="!allowedToView">
      <label>Enter Code:</label>
      <ecat-input
          id="aderlo-cashback-panel-login-input"
          type="password"
          v-model="form.secretCode"
          @should-reload="loadWalletDetails(true)"
      />
    </div>

    <div v-else>
      <div class="row cashback-header mt-3" v-if="walletDetails">
        <div class="col-12">
          <div class="row p-0 align-items-center">
            <div class="col-12 col-lg-7">
              <div class="row pb-1">
                <div class="col-12 col-lg-8 p-0 pr-1">
                  <div class="cashback-header p-0 d-flex flex-row align-items-center">
                    <div class="cashback-icon-box d-flex align-items-center justify-content-center">
                      <img src="@/assets/images/brands/aderlo-cashback.svg" alt="Aderlo Cashback" class="cashback-icon"/>
                    </div>

                    <div class="flex-grow-1 pl-2">
                    <span class="font-size-14 font-weight-light">
                      {{ $t('aderlo-cashback.your-available-acc-token') }}
                    </span>
                      <i id="token-acc-description"
                         class="ri ri-information-line feather feather-info mx-2 font-size-14"></i>
                      <b-tooltip target="token-acc-description" placement="right">{{
                          $t('aderlo-cashback.acc-token-description')
                        }}
                      </b-tooltip>
                      <div class="font-weight-bold font-size-30 color-secondary">{{
                          getBalance('ACC') | formatEthBalance
                        }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-lg-4 p-0 pl-1">
                  <div class="cashback-header">
                    <div class="color-primary font-size-14">{{ $t('aderlo-cashback.bnb-amount') }}
                    </div>

                    <div class="color-secondary font-size-30 font-weight-bold">
                      {{ getBalance('BNB') | formatEthBalance }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row pt-1">
                <div class="col-12 d-flex align-items-center justify-content-between cashback-header py-1">
                  <div class="d-flex flex-row align-items-center">
                    <label class="m-0">{{ $t('aderlo-cashback.wallet-address') }}</label>
                    <div class="pl-2 color-secondary"> {{ walletDetails.wallet.address }}</div>
                    <i class="ri-file-copy-line font-size-18 icon-copy"
                       @click="copyToClipboard(walletDetails.wallet.address)"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div v-else>
        <div class="text-center">
          <h5>{{ $t('message.loading') }}</h5>
          <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
        </div>
      </div>

      <div class="row mt-3">
        <div class="col-12 col-lg-6">
          <div class="card">
            <template v-if="notificationRule">
            <custom-card-title title="Powiadomienia"/>
            <div class="card-body">
              <div class="row">
                <custom-card-subtitle title="Kiedy wysłać SMS z powiadomieniem:"/>

                <div class="col-12 col-lg-6">
                  <div class="custom-control custom-checkbox mb-4">
                    <input
                        v-model="notificationRuleOptions.sendBelowAccAmount"
                        type="checkbox"
                        class="custom-control-input"
                        id="accControl"
                    />
                    <label
                        class="custom-control-label"
                        for="accControl"
                    >Gdy liczba ACC w portfelu spadnie poniżej:</label>
                  </div>
                  <ecat-input
                      v-model="notificationRule.sendBelowAccAmount"
                      id="accMin-input"
                      type="number"
                  />
                </div>

                <div class="col-12 col-lg-6">
                  <div class="custom-control custom-checkbox mb-4">
                    <input
                        v-model="notificationRuleOptions.sendBelowBnbAmount"
                        type="checkbox"
                        class="custom-control-input"
                        id="bnbControl"
                    />
                    <label
                        class="custom-control-label"
                        for="bnbControl"
                    >Gdy liczba BNB w portfelu spadnie poniżej:</label>
                  </div>

                  <ecat-input
                      v-model="notificationRule.sendBelowBnbAmount"
                      id="bnbMin-input"
                      type="number"
                  />
                </div>
              </div>
              <div class="row mt-1">
                <custom-card-subtitle title="Powiadomienia mają być wysyłane na numery:"/>

                <div class="col-12 col-lg-4" v-for="(phoneNumber, index) in notificationRuleOptions.phoneNumbers" :key="index">
                  <div class="input-container">
                    <span @click="removePhoneNumber(index)" class="clear-icon">
                      <span class="clickable-element text-danger font-size-14">
                        <i class="fa fa-times"/>
                      </span>
                    </span>

                    <input
                        :value="phoneNumber"
                        @input="event => changePhoneNumber(index, event.target.value)"
                        type="text"
                        class="form-control input-field"
                        :class="!isPossibleNumber(phoneNumber) ? 'is-invalid' : ''"
                    >
                    <div v-if="!isPossibleNumber(phoneNumber)" class="invalid-feedback">{{ $t('message.number-error') }}</div>
                  </div>
                </div>

                <div class="col-12 col-lg-4">
                  <div @click="addPhoneNumber" class="btn btn-outline-primary w-100">+ Dodaj Numer</div>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col-12 text-center">
                  <ecat-button :callback="updateNotificationRule" variant="success" message="Zapisz" />
                </div>
              </div>
            </div>
            </template>
            <template v-else>
              <div class="text-center">
                <h5>{{ $t('message.loading') }}</h5>
                <span aria-hidden="true" class="m-2 spinner-border text-primary"/>
              </div>
            </template>
          </div>
        </div>
        <div class="col-lg-6">
          <div class="card">
            <custom-card-title title="Staking poboczny" />
            <div class="card-body">
              <div class="row">
                <div @click="$refs.createStakingModal.openModal()" class="btn btn-primary w-100 col-12">+ Utwórz nowy Staking Poboczny</div>
                <div class="col-lg-6 mt-4" v-for="(staking, index) in stakingList" :key="index">
                  <div class="staking-box p-0">
                    <div class="row">
                      <div class="col-12">
                        <div class="staking-header d-flex align-items-center justify-content-center">
                          {{ staking.name }}
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="staking-box-body text-center">
                          <div class="staking-box-title">
                            Kwota:
                          </div>
                          <div class="staking-box-body-subtitle">
                           {{ staking.maxAmount | formatEthBalance }} {{  staking.tokenSymbol }}
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6 pr-0">
                        <div class="staking-box-footer text-center ">
                          <div class="staking-box-title">
                            Okres:
                          </div>
                          <div class="staking-box-subtitle">
                            {{ dateUtil.asDateNormal(staking.fromDate) }} - {{ dateUtil.asDateNormal(staking.toDate) }}
                          </div>
                        </div>

                      </div>
                      <div class="col-12 col-lg-6 ps-0">
                        <div class="staking-box-footer text-center ">
                          <div class="staking-box-title">
                            Godzina:
                          </div>
                          <div class="staking-box-subtitle">
                            {{ timeUtil.asTime(staking.rewardTime) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<style>

.staking-box {
  background: #2A313E;
  border: 1px solid #373E4C;
  border-radius: 5px;
}

.staking-box-footer {
  padding: 15px 0;
}

.staking-box-footer:first-child {
  border-right: 1px solid #373E4C;
}

.staking-header {
  color: #E1E1E1;
  background: #373E4C;
  border: 1px solid #373E4C;
  border-radius: 5px;
  padding: 15px 0;

  font-size: 14px;
}

.staking-box-title {
  color: #AFB2BB;
  font-size: 12px;
}

.staking-box-body {
  padding: 20px 0;
  border-bottom: 1px solid #373E4C;
}

.staking-box-body-subtitle {
  color: #4892F1;
  font-size: 12px;
  font-weight: 600;
}

.staking-box-subtitle {
  color: #E1E1E1;
  font-size: 12px;
  font-weight: 600;
}

.cashback-header {
  padding: 10px 20px;
  margin: 0;
  height: 100%;
  border-radius: 5px;
  background: rgba(72, 146, 241, 0.1);
}

.custom-badge-green {
  background: rgba(18, 142, 108, 0.25);
  color: rgba(34, 180, 140, 1);
}

.custom-badge-red {
  background: rgba(241, 72, 72, 0.25);
  color: rgba(180, 34, 34, 1);
}

.custom-badge {
  display: flex;
  align-items: center;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
  padding: 0 5px;
  width: fit-content;
}

.icon-copy {
  color: #E7EAF4;
  padding-left: 15px;
  cursor: pointer;
}

.cashback-icon-box {
  border-radius: 5px;
  background: rgba(72, 146, 241, 0.1);
  width: 90px;
  height: 90px;
}

.input-container {
  position: relative;
}

.clear-icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.input-field {
  padding-left: 25px;
}
</style>