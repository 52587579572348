<template>
  <b-modal
      v-model="visibleModal"
      title="Utwórz Nowy Staking Poboczny"
      title-class="font-16"
      hide-footer
      centered
      @hide="hideModal"
      @esc="hideModal"
  >
    <div class="form-group">
      <label>Nazwa stakingu:</label>
      <input v-model="form.name" type="text" class="form-control" placeholder="Nazwa Stakingu" :class="{ 'is-invalid': $v.form.name.$error }" />
      <div v-if="$v.form.name.$error" class="invalid-feedback">{{ $t('message.required') }}</div>
    </div>

    <div class="form-group">
      <label>Kwota [ACC]:</label>
      <input v-model="form.maxAmount" type="number" class="form-control" :class="{ 'is-invalid': $v.form.maxAmount.$error }" />
      <div v-if="$v.form.maxAmount.$error" class="invalid-feedback">{{ $t('message.required') }}</div>
    </div>

    <div class="form-group">
      <p class="text-center">Okres przez który ten staking poboczny ma obowiązywać</p>
    </div>

    <div class="row mb-4">
      <div class="col-12 col-lg-6">
        <label>Od:</label>
        <input v-model="form.fromDate" type="text" class="form-control" placeholder="DD.MM.RRRR" :class="{ 'is-invalid': $v.form.fromDate.$error }" />
        <div v-if="!$v.form.fromDate.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        <div v-if="!$v.form.fromDate.isDate" class="invalid-feedback">Błędny format! Poprawny: DD.MM.RRRR</div>
      </div>

      <div class="col-12 col-lg-6">
        <label>Do:</label>
        <input v-model="form.toDate" type="text" class="form-control" placeholder="DD.MM.RRRR" :class="{ 'is-invalid': $v.form.toDate.$error }" />
        <div v-if="$v.form.toDate.$error" class="invalid-feedback">{{ $t('message.required') }}</div>
        <div v-if="!$v.form.toDate.isDate" class="invalid-feedback">Błędny format! Poprawny: DD.MM.RRRR</div>
      </div>
    </div>

    <div class="row mb-4">
      <div class="col-12 col-lg-6">
        <label>Godzina Rozdawania ACC:</label>
        <input v-model="form.rewardTime" type="text" class="form-control" placeholder="00:00" :class="{ 'is-invalid': $v.form.rewardTime.$error }" />
        <div v-if="!$v.form.rewardTime.required" class="invalid-feedback">{{ $t('message.required') }}</div>
        <div v-if="!$v.form.rewardTime.isTime" class="invalid-feedback">Błędny format! Poprawny: HH.MM</div>
      </div>
    </div>

    <div class="row px-2">
      <div class="col-8 p-0 pr-2">
        <ecat-button :callback="createOrEdit" variant="primary" class="w-100" message="Utwórz Staking" />
      </div>
      <div class="col-4 p-0 pl-2">
        <b-button @click="hideModal" variant="danger" class="w-100">
          {{  $t('message.cancel') }}
        </b-button>
      </div>
    </div>

  </b-modal>
</template>

<script>
import {required} from "vuelidate/lib/validators";
import axios from "axios";
import {errorCatcher} from "@/helpers/error-catcher";
import Swal from "sweetalert2";

export default {

  props: {
    secretCode: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      visibleModal: false,

      form: {
        tokenSymbol: "ACC",
        name: "",
        maxAmount: 0,
        fromDate: "",
        toDate: "",
        rewardTime: ""
      }
    }
  },

  validations: {
    form: {
      name: { required },
      maxAmount: { required },
      fromDate: {
        required,
        isDate: function(value) {
          return /^\d{2}\.\d{2}\.\d{4}$/.test(value);
        }
      },
      toDate: {
        required,
        isDate: function(value) {
          return /^\d{2}\.\d{2}\.\d{4}$/.test(value);
        }
      },
      rewardTime: {
        required,
        isTime: function(value) {
          return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(value)
        }
      }
    }
  },

  methods: {
    openModal() {
      this.visibleModal = true
    },

    hideModal() {
      this.visibleModal = false
      this.$v.form.$reset()
    },

    async createOrEdit() {
      this.$v.form.$touch();

      if (this.$v.form.$invalid) {
        return;
      }

      const json = JSON.stringify({
        tokenSymbol: this.form.tokenSymbol,
        name: this.form.name,
        maxAmount: this.form.maxAmount,
        fromDate: `${this.convertDate(this.form.fromDate.replaceAll(".", "-"))}T01:00:00.952269+01`,
        toDate: `${this.convertDate(this.form.toDate.replaceAll(".", "-"))}T01:00:00.952269+01`,
        rewardTime: `${this.form.rewardTime}:20.999923900+01:00`
      })

      try {
        await axios.put(`/cryptocurrency/staking/reward`, json, {
          headers: {
            'Secret-Code': this.secretCode,
            'Content-Type': 'application/json',
            Accept: 'application/json'
          }
        })

        this.hideModal()
        Swal.fire(this.$t("message.success"), "Pomyslnie utworzono Poboczny Staking", "success")
            .then(() => this.$emit("load-staking-list"))
      } catch (error) {
        errorCatcher.catchErrors(error)
      }
    },

    convertDate(data) {
      const parts = data.split("-");
      return parts[2] + "-" + parts[1] + "-" + parts[0];
    }
  }

}
</script>